/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  SwitchField,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import {
  Company,
  User,
  Client,
  Specie,
  Variety,
  Grower,
  Field,
  Exporter,
  Packing,
  PackFormat,
  SizeFormat,
  IntakeFormat,
  IntakeReport,
  FinishReport,
  DestinationReport,
  VisitReport,
} from "../models";
import {
  fetchByPath,
  getOverrideProps,
  useDataStoreBinding,
  validateField,
} from "./utils";
import { DataStore } from "aws-amplify";
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function CompanyCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    email: "",
    photo: "",
    location: "",
    isActive: false,
    CompanyUsers: [],
    CompanyClients: [],
    CompanySpecies: [],
    CompanyVarieties: [],
    CompanyGrowers: [],
    CompanyFields: [],
    CompanyExporters: [],
    CompanyPackings: [],
    CompanyPackFormats: [],
    CompanySizeFormats: [],
    CompanyIntakeFormats: [],
    CompanyIntakeReports: [],
    CompanyFinishReports: [],
    CompanyDestinationReports: [],
    CompanyVisitReports: [],
  };
  const [name, setName] = React.useState(initialValues.name);
  const [email, setEmail] = React.useState(initialValues.email);
  const [photo, setPhoto] = React.useState(initialValues.photo);
  const [location, setLocation] = React.useState(initialValues.location);
  const [isActive, setIsActive] = React.useState(initialValues.isActive);
  const [CompanyUsers, setCompanyUsers] = React.useState(
    initialValues.CompanyUsers
  );
  const [CompanyClients, setCompanyClients] = React.useState(
    initialValues.CompanyClients
  );
  const [CompanySpecies, setCompanySpecies] = React.useState(
    initialValues.CompanySpecies
  );
  const [CompanyVarieties, setCompanyVarieties] = React.useState(
    initialValues.CompanyVarieties
  );
  const [CompanyGrowers, setCompanyGrowers] = React.useState(
    initialValues.CompanyGrowers
  );
  const [CompanyFields, setCompanyFields] = React.useState(
    initialValues.CompanyFields
  );
  const [CompanyExporters, setCompanyExporters] = React.useState(
    initialValues.CompanyExporters
  );
  const [CompanyPackings, setCompanyPackings] = React.useState(
    initialValues.CompanyPackings
  );
  const [CompanyPackFormats, setCompanyPackFormats] = React.useState(
    initialValues.CompanyPackFormats
  );
  const [CompanySizeFormats, setCompanySizeFormats] = React.useState(
    initialValues.CompanySizeFormats
  );
  const [CompanyIntakeFormats, setCompanyIntakeFormats] = React.useState(
    initialValues.CompanyIntakeFormats
  );
  const [CompanyIntakeReports, setCompanyIntakeReports] = React.useState(
    initialValues.CompanyIntakeReports
  );
  const [CompanyFinishReports, setCompanyFinishReports] = React.useState(
    initialValues.CompanyFinishReports
  );
  const [CompanyDestinationReports, setCompanyDestinationReports] =
    React.useState(initialValues.CompanyDestinationReports);
  const [CompanyVisitReports, setCompanyVisitReports] = React.useState(
    initialValues.CompanyVisitReports
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setName(initialValues.name);
    setEmail(initialValues.email);
    setPhoto(initialValues.photo);
    setLocation(initialValues.location);
    setIsActive(initialValues.isActive);
    setCompanyUsers(initialValues.CompanyUsers);
    setCurrentCompanyUsersValue(undefined);
    setCurrentCompanyUsersDisplayValue("");
    setCompanyClients(initialValues.CompanyClients);
    setCurrentCompanyClientsValue(undefined);
    setCurrentCompanyClientsDisplayValue("");
    setCompanySpecies(initialValues.CompanySpecies);
    setCurrentCompanySpeciesValue(undefined);
    setCurrentCompanySpeciesDisplayValue("");
    setCompanyVarieties(initialValues.CompanyVarieties);
    setCurrentCompanyVarietiesValue(undefined);
    setCurrentCompanyVarietiesDisplayValue("");
    setCompanyGrowers(initialValues.CompanyGrowers);
    setCurrentCompanyGrowersValue(undefined);
    setCurrentCompanyGrowersDisplayValue("");
    setCompanyFields(initialValues.CompanyFields);
    setCurrentCompanyFieldsValue(undefined);
    setCurrentCompanyFieldsDisplayValue("");
    setCompanyExporters(initialValues.CompanyExporters);
    setCurrentCompanyExportersValue(undefined);
    setCurrentCompanyExportersDisplayValue("");
    setCompanyPackings(initialValues.CompanyPackings);
    setCurrentCompanyPackingsValue(undefined);
    setCurrentCompanyPackingsDisplayValue("");
    setCompanyPackFormats(initialValues.CompanyPackFormats);
    setCurrentCompanyPackFormatsValue(undefined);
    setCurrentCompanyPackFormatsDisplayValue("");
    setCompanySizeFormats(initialValues.CompanySizeFormats);
    setCurrentCompanySizeFormatsValue(undefined);
    setCurrentCompanySizeFormatsDisplayValue("");
    setCompanyIntakeFormats(initialValues.CompanyIntakeFormats);
    setCurrentCompanyIntakeFormatsValue(undefined);
    setCurrentCompanyIntakeFormatsDisplayValue("");
    setCompanyIntakeReports(initialValues.CompanyIntakeReports);
    setCurrentCompanyIntakeReportsValue(undefined);
    setCurrentCompanyIntakeReportsDisplayValue("");
    setCompanyFinishReports(initialValues.CompanyFinishReports);
    setCurrentCompanyFinishReportsValue(undefined);
    setCurrentCompanyFinishReportsDisplayValue("");
    setCompanyDestinationReports(initialValues.CompanyDestinationReports);
    setCurrentCompanyDestinationReportsValue(undefined);
    setCurrentCompanyDestinationReportsDisplayValue("");
    setCompanyVisitReports(initialValues.CompanyVisitReports);
    setCurrentCompanyVisitReportsValue(undefined);
    setCurrentCompanyVisitReportsDisplayValue("");
    setErrors({});
  };
  const [currentCompanyUsersDisplayValue, setCurrentCompanyUsersDisplayValue] =
    React.useState("");
  const [currentCompanyUsersValue, setCurrentCompanyUsersValue] =
    React.useState(undefined);
  const CompanyUsersRef = React.createRef();
  const [
    currentCompanyClientsDisplayValue,
    setCurrentCompanyClientsDisplayValue,
  ] = React.useState("");
  const [currentCompanyClientsValue, setCurrentCompanyClientsValue] =
    React.useState(undefined);
  const CompanyClientsRef = React.createRef();
  const [
    currentCompanySpeciesDisplayValue,
    setCurrentCompanySpeciesDisplayValue,
  ] = React.useState("");
  const [currentCompanySpeciesValue, setCurrentCompanySpeciesValue] =
    React.useState(undefined);
  const CompanySpeciesRef = React.createRef();
  const [
    currentCompanyVarietiesDisplayValue,
    setCurrentCompanyVarietiesDisplayValue,
  ] = React.useState("");
  const [currentCompanyVarietiesValue, setCurrentCompanyVarietiesValue] =
    React.useState(undefined);
  const CompanyVarietiesRef = React.createRef();
  const [
    currentCompanyGrowersDisplayValue,
    setCurrentCompanyGrowersDisplayValue,
  ] = React.useState("");
  const [currentCompanyGrowersValue, setCurrentCompanyGrowersValue] =
    React.useState(undefined);
  const CompanyGrowersRef = React.createRef();
  const [
    currentCompanyFieldsDisplayValue,
    setCurrentCompanyFieldsDisplayValue,
  ] = React.useState("");
  const [currentCompanyFieldsValue, setCurrentCompanyFieldsValue] =
    React.useState(undefined);
  const CompanyFieldsRef = React.createRef();
  const [
    currentCompanyExportersDisplayValue,
    setCurrentCompanyExportersDisplayValue,
  ] = React.useState("");
  const [currentCompanyExportersValue, setCurrentCompanyExportersValue] =
    React.useState(undefined);
  const CompanyExportersRef = React.createRef();
  const [
    currentCompanyPackingsDisplayValue,
    setCurrentCompanyPackingsDisplayValue,
  ] = React.useState("");
  const [currentCompanyPackingsValue, setCurrentCompanyPackingsValue] =
    React.useState(undefined);
  const CompanyPackingsRef = React.createRef();
  const [
    currentCompanyPackFormatsDisplayValue,
    setCurrentCompanyPackFormatsDisplayValue,
  ] = React.useState("");
  const [currentCompanyPackFormatsValue, setCurrentCompanyPackFormatsValue] =
    React.useState(undefined);
  const CompanyPackFormatsRef = React.createRef();
  const [
    currentCompanySizeFormatsDisplayValue,
    setCurrentCompanySizeFormatsDisplayValue,
  ] = React.useState("");
  const [currentCompanySizeFormatsValue, setCurrentCompanySizeFormatsValue] =
    React.useState(undefined);
  const CompanySizeFormatsRef = React.createRef();
  const [
    currentCompanyIntakeFormatsDisplayValue,
    setCurrentCompanyIntakeFormatsDisplayValue,
  ] = React.useState("");
  const [
    currentCompanyIntakeFormatsValue,
    setCurrentCompanyIntakeFormatsValue,
  ] = React.useState(undefined);
  const CompanyIntakeFormatsRef = React.createRef();
  const [
    currentCompanyIntakeReportsDisplayValue,
    setCurrentCompanyIntakeReportsDisplayValue,
  ] = React.useState("");
  const [
    currentCompanyIntakeReportsValue,
    setCurrentCompanyIntakeReportsValue,
  ] = React.useState(undefined);
  const CompanyIntakeReportsRef = React.createRef();
  const [
    currentCompanyFinishReportsDisplayValue,
    setCurrentCompanyFinishReportsDisplayValue,
  ] = React.useState("");
  const [
    currentCompanyFinishReportsValue,
    setCurrentCompanyFinishReportsValue,
  ] = React.useState(undefined);
  const CompanyFinishReportsRef = React.createRef();
  const [
    currentCompanyDestinationReportsDisplayValue,
    setCurrentCompanyDestinationReportsDisplayValue,
  ] = React.useState("");
  const [
    currentCompanyDestinationReportsValue,
    setCurrentCompanyDestinationReportsValue,
  ] = React.useState(undefined);
  const CompanyDestinationReportsRef = React.createRef();
  const [
    currentCompanyVisitReportsDisplayValue,
    setCurrentCompanyVisitReportsDisplayValue,
  ] = React.useState("");
  const [currentCompanyVisitReportsValue, setCurrentCompanyVisitReportsValue] =
    React.useState(undefined);
  const CompanyVisitReportsRef = React.createRef();
  const getIDValue = {
    CompanyUsers: (r) => JSON.stringify({ id: r?.id }),
    CompanyClients: (r) => JSON.stringify({ id: r?.id }),
    CompanySpecies: (r) => JSON.stringify({ id: r?.id }),
    CompanyVarieties: (r) => JSON.stringify({ id: r?.id }),
    CompanyGrowers: (r) => JSON.stringify({ id: r?.id }),
    CompanyFields: (r) => JSON.stringify({ id: r?.id }),
    CompanyExporters: (r) => JSON.stringify({ id: r?.id }),
    CompanyPackings: (r) => JSON.stringify({ id: r?.id }),
    CompanyPackFormats: (r) => JSON.stringify({ id: r?.id }),
    CompanySizeFormats: (r) => JSON.stringify({ id: r?.id }),
    CompanyIntakeFormats: (r) => JSON.stringify({ id: r?.id }),
    CompanyIntakeReports: (r) => JSON.stringify({ id: r?.id }),
    CompanyFinishReports: (r) => JSON.stringify({ id: r?.id }),
    CompanyDestinationReports: (r) => JSON.stringify({ id: r?.id }),
    CompanyVisitReports: (r) => JSON.stringify({ id: r?.id }),
  };
  const CompanyUsersIdSet = new Set(
    Array.isArray(CompanyUsers)
      ? CompanyUsers.map((r) => getIDValue.CompanyUsers?.(r))
      : getIDValue.CompanyUsers?.(CompanyUsers)
  );
  const CompanyClientsIdSet = new Set(
    Array.isArray(CompanyClients)
      ? CompanyClients.map((r) => getIDValue.CompanyClients?.(r))
      : getIDValue.CompanyClients?.(CompanyClients)
  );
  const CompanySpeciesIdSet = new Set(
    Array.isArray(CompanySpecies)
      ? CompanySpecies.map((r) => getIDValue.CompanySpecies?.(r))
      : getIDValue.CompanySpecies?.(CompanySpecies)
  );
  const CompanyVarietiesIdSet = new Set(
    Array.isArray(CompanyVarieties)
      ? CompanyVarieties.map((r) => getIDValue.CompanyVarieties?.(r))
      : getIDValue.CompanyVarieties?.(CompanyVarieties)
  );
  const CompanyGrowersIdSet = new Set(
    Array.isArray(CompanyGrowers)
      ? CompanyGrowers.map((r) => getIDValue.CompanyGrowers?.(r))
      : getIDValue.CompanyGrowers?.(CompanyGrowers)
  );
  const CompanyFieldsIdSet = new Set(
    Array.isArray(CompanyFields)
      ? CompanyFields.map((r) => getIDValue.CompanyFields?.(r))
      : getIDValue.CompanyFields?.(CompanyFields)
  );
  const CompanyExportersIdSet = new Set(
    Array.isArray(CompanyExporters)
      ? CompanyExporters.map((r) => getIDValue.CompanyExporters?.(r))
      : getIDValue.CompanyExporters?.(CompanyExporters)
  );
  const CompanyPackingsIdSet = new Set(
    Array.isArray(CompanyPackings)
      ? CompanyPackings.map((r) => getIDValue.CompanyPackings?.(r))
      : getIDValue.CompanyPackings?.(CompanyPackings)
  );
  const CompanyPackFormatsIdSet = new Set(
    Array.isArray(CompanyPackFormats)
      ? CompanyPackFormats.map((r) => getIDValue.CompanyPackFormats?.(r))
      : getIDValue.CompanyPackFormats?.(CompanyPackFormats)
  );
  const CompanySizeFormatsIdSet = new Set(
    Array.isArray(CompanySizeFormats)
      ? CompanySizeFormats.map((r) => getIDValue.CompanySizeFormats?.(r))
      : getIDValue.CompanySizeFormats?.(CompanySizeFormats)
  );
  const CompanyIntakeFormatsIdSet = new Set(
    Array.isArray(CompanyIntakeFormats)
      ? CompanyIntakeFormats.map((r) => getIDValue.CompanyIntakeFormats?.(r))
      : getIDValue.CompanyIntakeFormats?.(CompanyIntakeFormats)
  );
  const CompanyIntakeReportsIdSet = new Set(
    Array.isArray(CompanyIntakeReports)
      ? CompanyIntakeReports.map((r) => getIDValue.CompanyIntakeReports?.(r))
      : getIDValue.CompanyIntakeReports?.(CompanyIntakeReports)
  );
  const CompanyFinishReportsIdSet = new Set(
    Array.isArray(CompanyFinishReports)
      ? CompanyFinishReports.map((r) => getIDValue.CompanyFinishReports?.(r))
      : getIDValue.CompanyFinishReports?.(CompanyFinishReports)
  );
  const CompanyDestinationReportsIdSet = new Set(
    Array.isArray(CompanyDestinationReports)
      ? CompanyDestinationReports.map((r) =>
          getIDValue.CompanyDestinationReports?.(r)
        )
      : getIDValue.CompanyDestinationReports?.(CompanyDestinationReports)
  );
  const CompanyVisitReportsIdSet = new Set(
    Array.isArray(CompanyVisitReports)
      ? CompanyVisitReports.map((r) => getIDValue.CompanyVisitReports?.(r))
      : getIDValue.CompanyVisitReports?.(CompanyVisitReports)
  );
  const userRecords = useDataStoreBinding({
    type: "collection",
    model: User,
  }).items;
  const clientRecords = useDataStoreBinding({
    type: "collection",
    model: Client,
  }).items;
  const specieRecords = useDataStoreBinding({
    type: "collection",
    model: Specie,
  }).items;
  const varietyRecords = useDataStoreBinding({
    type: "collection",
    model: Variety,
  }).items;
  const growerRecords = useDataStoreBinding({
    type: "collection",
    model: Grower,
  }).items;
  const fieldRecords = useDataStoreBinding({
    type: "collection",
    model: Field,
  }).items;
  const exporterRecords = useDataStoreBinding({
    type: "collection",
    model: Exporter,
  }).items;
  const packingRecords = useDataStoreBinding({
    type: "collection",
    model: Packing,
  }).items;
  const packFormatRecords = useDataStoreBinding({
    type: "collection",
    model: PackFormat,
  }).items;
  const sizeFormatRecords = useDataStoreBinding({
    type: "collection",
    model: SizeFormat,
  }).items;
  const intakeFormatRecords = useDataStoreBinding({
    type: "collection",
    model: IntakeFormat,
  }).items;
  const intakeReportRecords = useDataStoreBinding({
    type: "collection",
    model: IntakeReport,
  }).items;
  const finishReportRecords = useDataStoreBinding({
    type: "collection",
    model: FinishReport,
  }).items;
  const destinationReportRecords = useDataStoreBinding({
    type: "collection",
    model: DestinationReport,
  }).items;
  const visitReportRecords = useDataStoreBinding({
    type: "collection",
    model: VisitReport,
  }).items;
  const getDisplayValue = {
    CompanyUsers: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CompanyClients: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CompanySpecies: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CompanyVarieties: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CompanyGrowers: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CompanyFields: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CompanyExporters: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CompanyPackings: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CompanyPackFormats: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CompanySizeFormats: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CompanyIntakeFormats: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CompanyIntakeReports: (r) =>
      `${r?.reportType ? r?.reportType + " - " : ""}${r?.id}`,
    CompanyFinishReports: (r) =>
      `${r?.reportType ? r?.reportType + " - " : ""}${r?.id}`,
    CompanyDestinationReports: (r) =>
      `${r?.reportType ? r?.reportType + " - " : ""}${r?.id}`,
    CompanyVisitReports: (r) =>
      `${r?.reportType ? r?.reportType + " - " : ""}${r?.id}`,
  };
  const validations = {
    name: [{ type: "Required" }],
    email: [{ type: "Required" }, { type: "Email" }],
    photo: [],
    location: [],
    isActive: [{ type: "Required" }],
    CompanyUsers: [],
    CompanyClients: [],
    CompanySpecies: [],
    CompanyVarieties: [],
    CompanyGrowers: [],
    CompanyFields: [],
    CompanyExporters: [],
    CompanyPackings: [],
    CompanyPackFormats: [],
    CompanySizeFormats: [],
    CompanyIntakeFormats: [],
    CompanyIntakeReports: [],
    CompanyFinishReports: [],
    CompanyDestinationReports: [],
    CompanyVisitReports: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          email,
          photo,
          location,
          isActive,
          CompanyUsers,
          CompanyClients,
          CompanySpecies,
          CompanyVarieties,
          CompanyGrowers,
          CompanyFields,
          CompanyExporters,
          CompanyPackings,
          CompanyPackFormats,
          CompanySizeFormats,
          CompanyIntakeFormats,
          CompanyIntakeReports,
          CompanyFinishReports,
          CompanyDestinationReports,
          CompanyVisitReports,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            name: modelFields.name,
            email: modelFields.email,
            photo: modelFields.photo,
            location: modelFields.location,
            isActive: modelFields.isActive,
          };
          const company = await DataStore.save(new Company(modelFieldsToSave));
          const promises = [];
          promises.push(
            ...CompanyUsers.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  User.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyClients.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  Client.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanySpecies.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  Specie.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyVarieties.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  Variety.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyGrowers.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  Grower.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyFields.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  Field.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyExporters.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  Exporter.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyPackings.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  Packing.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyPackFormats.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  PackFormat.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanySizeFormats.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  SizeFormat.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyIntakeFormats.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  IntakeFormat.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyIntakeReports.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  IntakeReport.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyFinishReports.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  FinishReport.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyDestinationReports.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  DestinationReport.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          promises.push(
            ...CompanyVisitReports.reduce((promises, original) => {
              promises.push(
                DataStore.save(
                  VisitReport.copyOf(original, (updated) => {
                    updated.companyID = company.id;
                  })
                )
              );
              return promises;
            }, [])
          );
          await Promise.all(promises);
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "CompanyCreateForm")}
      {...rest}
    >
      <TextField
        label="Name"
        isRequired={true}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={true}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email: value,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Photo"
        isRequired={false}
        isReadOnly={false}
        value={photo}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo: value,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            value = result?.photo ?? value;
          }
          if (errors.photo?.hasError) {
            runValidationTasks("photo", value);
          }
          setPhoto(value);
        }}
        onBlur={() => runValidationTasks("photo", photo)}
        errorMessage={errors.photo?.errorMessage}
        hasError={errors.photo?.hasError}
        {...getOverrideProps(overrides, "photo")}
      ></TextField>
      <TextField
        label="Location"
        isRequired={false}
        isReadOnly={false}
        value={location}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location: value,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            value = result?.location ?? value;
          }
          if (errors.location?.hasError) {
            runValidationTasks("location", value);
          }
          setLocation(value);
        }}
        onBlur={() => runValidationTasks("location", location)}
        errorMessage={errors.location?.errorMessage}
        hasError={errors.location?.hasError}
        {...getOverrideProps(overrides, "location")}
      ></TextField>
      <SwitchField
        label="Is active"
        defaultChecked={false}
        isDisabled={false}
        isChecked={isActive}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive: value,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            value = result?.isActive ?? value;
          }
          if (errors.isActive?.hasError) {
            runValidationTasks("isActive", value);
          }
          setIsActive(value);
        }}
        onBlur={() => runValidationTasks("isActive", isActive)}
        errorMessage={errors.isActive?.errorMessage}
        hasError={errors.isActive?.hasError}
        {...getOverrideProps(overrides, "isActive")}
      ></SwitchField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers: values,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyUsers ?? values;
          }
          setCompanyUsers(values);
          setCurrentCompanyUsersValue(undefined);
          setCurrentCompanyUsersDisplayValue("");
        }}
        currentFieldValue={currentCompanyUsersValue}
        label={"Company users"}
        items={CompanyUsers}
        hasError={errors?.CompanyUsers?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("CompanyUsers", currentCompanyUsersValue)
        }
        errorMessage={errors?.CompanyUsers?.errorMessage}
        getBadgeText={getDisplayValue.CompanyUsers}
        setFieldValue={(model) => {
          setCurrentCompanyUsersDisplayValue(
            model ? getDisplayValue.CompanyUsers(model) : ""
          );
          setCurrentCompanyUsersValue(model);
        }}
        inputFieldRef={CompanyUsersRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company users"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search User"
          value={currentCompanyUsersDisplayValue}
          options={userRecords
            .filter((r) => !CompanyUsersIdSet.has(getIDValue.CompanyUsers?.(r)))
            .map((r) => ({
              id: getIDValue.CompanyUsers?.(r),
              label: getDisplayValue.CompanyUsers?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyUsersValue(
              userRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyUsersDisplayValue(label);
            runValidationTasks("CompanyUsers", label);
          }}
          onClear={() => {
            setCurrentCompanyUsersDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyUsers?.hasError) {
              runValidationTasks("CompanyUsers", value);
            }
            setCurrentCompanyUsersDisplayValue(value);
            setCurrentCompanyUsersValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("CompanyUsers", currentCompanyUsersDisplayValue)
          }
          errorMessage={errors.CompanyUsers?.errorMessage}
          hasError={errors.CompanyUsers?.hasError}
          ref={CompanyUsersRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyUsers")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients: values,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyClients ?? values;
          }
          setCompanyClients(values);
          setCurrentCompanyClientsValue(undefined);
          setCurrentCompanyClientsDisplayValue("");
        }}
        currentFieldValue={currentCompanyClientsValue}
        label={"Company clients"}
        items={CompanyClients}
        hasError={errors?.CompanyClients?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("CompanyClients", currentCompanyClientsValue)
        }
        errorMessage={errors?.CompanyClients?.errorMessage}
        getBadgeText={getDisplayValue.CompanyClients}
        setFieldValue={(model) => {
          setCurrentCompanyClientsDisplayValue(
            model ? getDisplayValue.CompanyClients(model) : ""
          );
          setCurrentCompanyClientsValue(model);
        }}
        inputFieldRef={CompanyClientsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company clients"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Client"
          value={currentCompanyClientsDisplayValue}
          options={clientRecords
            .filter(
              (r) => !CompanyClientsIdSet.has(getIDValue.CompanyClients?.(r))
            )
            .map((r) => ({
              id: getIDValue.CompanyClients?.(r),
              label: getDisplayValue.CompanyClients?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyClientsValue(
              clientRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyClientsDisplayValue(label);
            runValidationTasks("CompanyClients", label);
          }}
          onClear={() => {
            setCurrentCompanyClientsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyClients?.hasError) {
              runValidationTasks("CompanyClients", value);
            }
            setCurrentCompanyClientsDisplayValue(value);
            setCurrentCompanyClientsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyClients",
              currentCompanyClientsDisplayValue
            )
          }
          errorMessage={errors.CompanyClients?.errorMessage}
          hasError={errors.CompanyClients?.hasError}
          ref={CompanyClientsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyClients")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies: values,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanySpecies ?? values;
          }
          setCompanySpecies(values);
          setCurrentCompanySpeciesValue(undefined);
          setCurrentCompanySpeciesDisplayValue("");
        }}
        currentFieldValue={currentCompanySpeciesValue}
        label={"Company species"}
        items={CompanySpecies}
        hasError={errors?.CompanySpecies?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("CompanySpecies", currentCompanySpeciesValue)
        }
        errorMessage={errors?.CompanySpecies?.errorMessage}
        getBadgeText={getDisplayValue.CompanySpecies}
        setFieldValue={(model) => {
          setCurrentCompanySpeciesDisplayValue(
            model ? getDisplayValue.CompanySpecies(model) : ""
          );
          setCurrentCompanySpeciesValue(model);
        }}
        inputFieldRef={CompanySpeciesRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company species"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Specie"
          value={currentCompanySpeciesDisplayValue}
          options={specieRecords
            .filter(
              (r) => !CompanySpeciesIdSet.has(getIDValue.CompanySpecies?.(r))
            )
            .map((r) => ({
              id: getIDValue.CompanySpecies?.(r),
              label: getDisplayValue.CompanySpecies?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanySpeciesValue(
              specieRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanySpeciesDisplayValue(label);
            runValidationTasks("CompanySpecies", label);
          }}
          onClear={() => {
            setCurrentCompanySpeciesDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanySpecies?.hasError) {
              runValidationTasks("CompanySpecies", value);
            }
            setCurrentCompanySpeciesDisplayValue(value);
            setCurrentCompanySpeciesValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanySpecies",
              currentCompanySpeciesDisplayValue
            )
          }
          errorMessage={errors.CompanySpecies?.errorMessage}
          hasError={errors.CompanySpecies?.hasError}
          ref={CompanySpeciesRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanySpecies")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties: values,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyVarieties ?? values;
          }
          setCompanyVarieties(values);
          setCurrentCompanyVarietiesValue(undefined);
          setCurrentCompanyVarietiesDisplayValue("");
        }}
        currentFieldValue={currentCompanyVarietiesValue}
        label={"Company varieties"}
        items={CompanyVarieties}
        hasError={errors?.CompanyVarieties?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "CompanyVarieties",
            currentCompanyVarietiesValue
          )
        }
        errorMessage={errors?.CompanyVarieties?.errorMessage}
        getBadgeText={getDisplayValue.CompanyVarieties}
        setFieldValue={(model) => {
          setCurrentCompanyVarietiesDisplayValue(
            model ? getDisplayValue.CompanyVarieties(model) : ""
          );
          setCurrentCompanyVarietiesValue(model);
        }}
        inputFieldRef={CompanyVarietiesRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company varieties"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Variety"
          value={currentCompanyVarietiesDisplayValue}
          options={varietyRecords
            .filter(
              (r) =>
                !CompanyVarietiesIdSet.has(getIDValue.CompanyVarieties?.(r))
            )
            .map((r) => ({
              id: getIDValue.CompanyVarieties?.(r),
              label: getDisplayValue.CompanyVarieties?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyVarietiesValue(
              varietyRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyVarietiesDisplayValue(label);
            runValidationTasks("CompanyVarieties", label);
          }}
          onClear={() => {
            setCurrentCompanyVarietiesDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyVarieties?.hasError) {
              runValidationTasks("CompanyVarieties", value);
            }
            setCurrentCompanyVarietiesDisplayValue(value);
            setCurrentCompanyVarietiesValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyVarieties",
              currentCompanyVarietiesDisplayValue
            )
          }
          errorMessage={errors.CompanyVarieties?.errorMessage}
          hasError={errors.CompanyVarieties?.hasError}
          ref={CompanyVarietiesRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyVarieties")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers: values,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyGrowers ?? values;
          }
          setCompanyGrowers(values);
          setCurrentCompanyGrowersValue(undefined);
          setCurrentCompanyGrowersDisplayValue("");
        }}
        currentFieldValue={currentCompanyGrowersValue}
        label={"Company growers"}
        items={CompanyGrowers}
        hasError={errors?.CompanyGrowers?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("CompanyGrowers", currentCompanyGrowersValue)
        }
        errorMessage={errors?.CompanyGrowers?.errorMessage}
        getBadgeText={getDisplayValue.CompanyGrowers}
        setFieldValue={(model) => {
          setCurrentCompanyGrowersDisplayValue(
            model ? getDisplayValue.CompanyGrowers(model) : ""
          );
          setCurrentCompanyGrowersValue(model);
        }}
        inputFieldRef={CompanyGrowersRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company growers"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Grower"
          value={currentCompanyGrowersDisplayValue}
          options={growerRecords
            .filter(
              (r) => !CompanyGrowersIdSet.has(getIDValue.CompanyGrowers?.(r))
            )
            .map((r) => ({
              id: getIDValue.CompanyGrowers?.(r),
              label: getDisplayValue.CompanyGrowers?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyGrowersValue(
              growerRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyGrowersDisplayValue(label);
            runValidationTasks("CompanyGrowers", label);
          }}
          onClear={() => {
            setCurrentCompanyGrowersDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyGrowers?.hasError) {
              runValidationTasks("CompanyGrowers", value);
            }
            setCurrentCompanyGrowersDisplayValue(value);
            setCurrentCompanyGrowersValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyGrowers",
              currentCompanyGrowersDisplayValue
            )
          }
          errorMessage={errors.CompanyGrowers?.errorMessage}
          hasError={errors.CompanyGrowers?.hasError}
          ref={CompanyGrowersRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyGrowers")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields: values,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyFields ?? values;
          }
          setCompanyFields(values);
          setCurrentCompanyFieldsValue(undefined);
          setCurrentCompanyFieldsDisplayValue("");
        }}
        currentFieldValue={currentCompanyFieldsValue}
        label={"Company fields"}
        items={CompanyFields}
        hasError={errors?.CompanyFields?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("CompanyFields", currentCompanyFieldsValue)
        }
        errorMessage={errors?.CompanyFields?.errorMessage}
        getBadgeText={getDisplayValue.CompanyFields}
        setFieldValue={(model) => {
          setCurrentCompanyFieldsDisplayValue(
            model ? getDisplayValue.CompanyFields(model) : ""
          );
          setCurrentCompanyFieldsValue(model);
        }}
        inputFieldRef={CompanyFieldsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company fields"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Field"
          value={currentCompanyFieldsDisplayValue}
          options={fieldRecords
            .filter(
              (r) => !CompanyFieldsIdSet.has(getIDValue.CompanyFields?.(r))
            )
            .map((r) => ({
              id: getIDValue.CompanyFields?.(r),
              label: getDisplayValue.CompanyFields?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyFieldsValue(
              fieldRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyFieldsDisplayValue(label);
            runValidationTasks("CompanyFields", label);
          }}
          onClear={() => {
            setCurrentCompanyFieldsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyFields?.hasError) {
              runValidationTasks("CompanyFields", value);
            }
            setCurrentCompanyFieldsDisplayValue(value);
            setCurrentCompanyFieldsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyFields",
              currentCompanyFieldsDisplayValue
            )
          }
          errorMessage={errors.CompanyFields?.errorMessage}
          hasError={errors.CompanyFields?.hasError}
          ref={CompanyFieldsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyFields")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters: values,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyExporters ?? values;
          }
          setCompanyExporters(values);
          setCurrentCompanyExportersValue(undefined);
          setCurrentCompanyExportersDisplayValue("");
        }}
        currentFieldValue={currentCompanyExportersValue}
        label={"Company exporters"}
        items={CompanyExporters}
        hasError={errors?.CompanyExporters?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "CompanyExporters",
            currentCompanyExportersValue
          )
        }
        errorMessage={errors?.CompanyExporters?.errorMessage}
        getBadgeText={getDisplayValue.CompanyExporters}
        setFieldValue={(model) => {
          setCurrentCompanyExportersDisplayValue(
            model ? getDisplayValue.CompanyExporters(model) : ""
          );
          setCurrentCompanyExportersValue(model);
        }}
        inputFieldRef={CompanyExportersRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company exporters"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Exporter"
          value={currentCompanyExportersDisplayValue}
          options={exporterRecords
            .filter(
              (r) =>
                !CompanyExportersIdSet.has(getIDValue.CompanyExporters?.(r))
            )
            .map((r) => ({
              id: getIDValue.CompanyExporters?.(r),
              label: getDisplayValue.CompanyExporters?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyExportersValue(
              exporterRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyExportersDisplayValue(label);
            runValidationTasks("CompanyExporters", label);
          }}
          onClear={() => {
            setCurrentCompanyExportersDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyExporters?.hasError) {
              runValidationTasks("CompanyExporters", value);
            }
            setCurrentCompanyExportersDisplayValue(value);
            setCurrentCompanyExportersValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyExporters",
              currentCompanyExportersDisplayValue
            )
          }
          errorMessage={errors.CompanyExporters?.errorMessage}
          hasError={errors.CompanyExporters?.hasError}
          ref={CompanyExportersRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyExporters")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings: values,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyPackings ?? values;
          }
          setCompanyPackings(values);
          setCurrentCompanyPackingsValue(undefined);
          setCurrentCompanyPackingsDisplayValue("");
        }}
        currentFieldValue={currentCompanyPackingsValue}
        label={"Company packings"}
        items={CompanyPackings}
        hasError={errors?.CompanyPackings?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "CompanyPackings",
            currentCompanyPackingsValue
          )
        }
        errorMessage={errors?.CompanyPackings?.errorMessage}
        getBadgeText={getDisplayValue.CompanyPackings}
        setFieldValue={(model) => {
          setCurrentCompanyPackingsDisplayValue(
            model ? getDisplayValue.CompanyPackings(model) : ""
          );
          setCurrentCompanyPackingsValue(model);
        }}
        inputFieldRef={CompanyPackingsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company packings"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Packing"
          value={currentCompanyPackingsDisplayValue}
          options={packingRecords
            .filter(
              (r) => !CompanyPackingsIdSet.has(getIDValue.CompanyPackings?.(r))
            )
            .map((r) => ({
              id: getIDValue.CompanyPackings?.(r),
              label: getDisplayValue.CompanyPackings?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyPackingsValue(
              packingRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyPackingsDisplayValue(label);
            runValidationTasks("CompanyPackings", label);
          }}
          onClear={() => {
            setCurrentCompanyPackingsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyPackings?.hasError) {
              runValidationTasks("CompanyPackings", value);
            }
            setCurrentCompanyPackingsDisplayValue(value);
            setCurrentCompanyPackingsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyPackings",
              currentCompanyPackingsDisplayValue
            )
          }
          errorMessage={errors.CompanyPackings?.errorMessage}
          hasError={errors.CompanyPackings?.hasError}
          ref={CompanyPackingsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyPackings")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats: values,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyPackFormats ?? values;
          }
          setCompanyPackFormats(values);
          setCurrentCompanyPackFormatsValue(undefined);
          setCurrentCompanyPackFormatsDisplayValue("");
        }}
        currentFieldValue={currentCompanyPackFormatsValue}
        label={"Company pack formats"}
        items={CompanyPackFormats}
        hasError={errors?.CompanyPackFormats?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "CompanyPackFormats",
            currentCompanyPackFormatsValue
          )
        }
        errorMessage={errors?.CompanyPackFormats?.errorMessage}
        getBadgeText={getDisplayValue.CompanyPackFormats}
        setFieldValue={(model) => {
          setCurrentCompanyPackFormatsDisplayValue(
            model ? getDisplayValue.CompanyPackFormats(model) : ""
          );
          setCurrentCompanyPackFormatsValue(model);
        }}
        inputFieldRef={CompanyPackFormatsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company pack formats"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search PackFormat"
          value={currentCompanyPackFormatsDisplayValue}
          options={packFormatRecords
            .filter(
              (r) =>
                !CompanyPackFormatsIdSet.has(getIDValue.CompanyPackFormats?.(r))
            )
            .map((r) => ({
              id: getIDValue.CompanyPackFormats?.(r),
              label: getDisplayValue.CompanyPackFormats?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyPackFormatsValue(
              packFormatRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyPackFormatsDisplayValue(label);
            runValidationTasks("CompanyPackFormats", label);
          }}
          onClear={() => {
            setCurrentCompanyPackFormatsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyPackFormats?.hasError) {
              runValidationTasks("CompanyPackFormats", value);
            }
            setCurrentCompanyPackFormatsDisplayValue(value);
            setCurrentCompanyPackFormatsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyPackFormats",
              currentCompanyPackFormatsDisplayValue
            )
          }
          errorMessage={errors.CompanyPackFormats?.errorMessage}
          hasError={errors.CompanyPackFormats?.hasError}
          ref={CompanyPackFormatsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyPackFormats")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats: values,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanySizeFormats ?? values;
          }
          setCompanySizeFormats(values);
          setCurrentCompanySizeFormatsValue(undefined);
          setCurrentCompanySizeFormatsDisplayValue("");
        }}
        currentFieldValue={currentCompanySizeFormatsValue}
        label={"Company size formats"}
        items={CompanySizeFormats}
        hasError={errors?.CompanySizeFormats?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "CompanySizeFormats",
            currentCompanySizeFormatsValue
          )
        }
        errorMessage={errors?.CompanySizeFormats?.errorMessage}
        getBadgeText={getDisplayValue.CompanySizeFormats}
        setFieldValue={(model) => {
          setCurrentCompanySizeFormatsDisplayValue(
            model ? getDisplayValue.CompanySizeFormats(model) : ""
          );
          setCurrentCompanySizeFormatsValue(model);
        }}
        inputFieldRef={CompanySizeFormatsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company size formats"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search SizeFormat"
          value={currentCompanySizeFormatsDisplayValue}
          options={sizeFormatRecords
            .filter(
              (r) =>
                !CompanySizeFormatsIdSet.has(getIDValue.CompanySizeFormats?.(r))
            )
            .map((r) => ({
              id: getIDValue.CompanySizeFormats?.(r),
              label: getDisplayValue.CompanySizeFormats?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanySizeFormatsValue(
              sizeFormatRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanySizeFormatsDisplayValue(label);
            runValidationTasks("CompanySizeFormats", label);
          }}
          onClear={() => {
            setCurrentCompanySizeFormatsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanySizeFormats?.hasError) {
              runValidationTasks("CompanySizeFormats", value);
            }
            setCurrentCompanySizeFormatsDisplayValue(value);
            setCurrentCompanySizeFormatsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanySizeFormats",
              currentCompanySizeFormatsDisplayValue
            )
          }
          errorMessage={errors.CompanySizeFormats?.errorMessage}
          hasError={errors.CompanySizeFormats?.hasError}
          ref={CompanySizeFormatsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanySizeFormats")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats: values,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyIntakeFormats ?? values;
          }
          setCompanyIntakeFormats(values);
          setCurrentCompanyIntakeFormatsValue(undefined);
          setCurrentCompanyIntakeFormatsDisplayValue("");
        }}
        currentFieldValue={currentCompanyIntakeFormatsValue}
        label={"Company intake formats"}
        items={CompanyIntakeFormats}
        hasError={errors?.CompanyIntakeFormats?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "CompanyIntakeFormats",
            currentCompanyIntakeFormatsValue
          )
        }
        errorMessage={errors?.CompanyIntakeFormats?.errorMessage}
        getBadgeText={getDisplayValue.CompanyIntakeFormats}
        setFieldValue={(model) => {
          setCurrentCompanyIntakeFormatsDisplayValue(
            model ? getDisplayValue.CompanyIntakeFormats(model) : ""
          );
          setCurrentCompanyIntakeFormatsValue(model);
        }}
        inputFieldRef={CompanyIntakeFormatsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company intake formats"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search IntakeFormat"
          value={currentCompanyIntakeFormatsDisplayValue}
          options={intakeFormatRecords
            .filter(
              (r) =>
                !CompanyIntakeFormatsIdSet.has(
                  getIDValue.CompanyIntakeFormats?.(r)
                )
            )
            .map((r) => ({
              id: getIDValue.CompanyIntakeFormats?.(r),
              label: getDisplayValue.CompanyIntakeFormats?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyIntakeFormatsValue(
              intakeFormatRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyIntakeFormatsDisplayValue(label);
            runValidationTasks("CompanyIntakeFormats", label);
          }}
          onClear={() => {
            setCurrentCompanyIntakeFormatsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyIntakeFormats?.hasError) {
              runValidationTasks("CompanyIntakeFormats", value);
            }
            setCurrentCompanyIntakeFormatsDisplayValue(value);
            setCurrentCompanyIntakeFormatsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyIntakeFormats",
              currentCompanyIntakeFormatsDisplayValue
            )
          }
          errorMessage={errors.CompanyIntakeFormats?.errorMessage}
          hasError={errors.CompanyIntakeFormats?.hasError}
          ref={CompanyIntakeFormatsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyIntakeFormats")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports: values,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyIntakeReports ?? values;
          }
          setCompanyIntakeReports(values);
          setCurrentCompanyIntakeReportsValue(undefined);
          setCurrentCompanyIntakeReportsDisplayValue("");
        }}
        currentFieldValue={currentCompanyIntakeReportsValue}
        label={"Company intake reports"}
        items={CompanyIntakeReports}
        hasError={errors?.CompanyIntakeReports?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "CompanyIntakeReports",
            currentCompanyIntakeReportsValue
          )
        }
        errorMessage={errors?.CompanyIntakeReports?.errorMessage}
        getBadgeText={getDisplayValue.CompanyIntakeReports}
        setFieldValue={(model) => {
          setCurrentCompanyIntakeReportsDisplayValue(
            model ? getDisplayValue.CompanyIntakeReports(model) : ""
          );
          setCurrentCompanyIntakeReportsValue(model);
        }}
        inputFieldRef={CompanyIntakeReportsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company intake reports"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search IntakeReport"
          value={currentCompanyIntakeReportsDisplayValue}
          options={intakeReportRecords
            .filter(
              (r) =>
                !CompanyIntakeReportsIdSet.has(
                  getIDValue.CompanyIntakeReports?.(r)
                )
            )
            .map((r) => ({
              id: getIDValue.CompanyIntakeReports?.(r),
              label: getDisplayValue.CompanyIntakeReports?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyIntakeReportsValue(
              intakeReportRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyIntakeReportsDisplayValue(label);
            runValidationTasks("CompanyIntakeReports", label);
          }}
          onClear={() => {
            setCurrentCompanyIntakeReportsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyIntakeReports?.hasError) {
              runValidationTasks("CompanyIntakeReports", value);
            }
            setCurrentCompanyIntakeReportsDisplayValue(value);
            setCurrentCompanyIntakeReportsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyIntakeReports",
              currentCompanyIntakeReportsDisplayValue
            )
          }
          errorMessage={errors.CompanyIntakeReports?.errorMessage}
          hasError={errors.CompanyIntakeReports?.hasError}
          ref={CompanyIntakeReportsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyIntakeReports")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports: values,
              CompanyDestinationReports,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyFinishReports ?? values;
          }
          setCompanyFinishReports(values);
          setCurrentCompanyFinishReportsValue(undefined);
          setCurrentCompanyFinishReportsDisplayValue("");
        }}
        currentFieldValue={currentCompanyFinishReportsValue}
        label={"Company finish reports"}
        items={CompanyFinishReports}
        hasError={errors?.CompanyFinishReports?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "CompanyFinishReports",
            currentCompanyFinishReportsValue
          )
        }
        errorMessage={errors?.CompanyFinishReports?.errorMessage}
        getBadgeText={getDisplayValue.CompanyFinishReports}
        setFieldValue={(model) => {
          setCurrentCompanyFinishReportsDisplayValue(
            model ? getDisplayValue.CompanyFinishReports(model) : ""
          );
          setCurrentCompanyFinishReportsValue(model);
        }}
        inputFieldRef={CompanyFinishReportsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company finish reports"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search FinishReport"
          value={currentCompanyFinishReportsDisplayValue}
          options={finishReportRecords
            .filter(
              (r) =>
                !CompanyFinishReportsIdSet.has(
                  getIDValue.CompanyFinishReports?.(r)
                )
            )
            .map((r) => ({
              id: getIDValue.CompanyFinishReports?.(r),
              label: getDisplayValue.CompanyFinishReports?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyFinishReportsValue(
              finishReportRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyFinishReportsDisplayValue(label);
            runValidationTasks("CompanyFinishReports", label);
          }}
          onClear={() => {
            setCurrentCompanyFinishReportsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyFinishReports?.hasError) {
              runValidationTasks("CompanyFinishReports", value);
            }
            setCurrentCompanyFinishReportsDisplayValue(value);
            setCurrentCompanyFinishReportsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyFinishReports",
              currentCompanyFinishReportsDisplayValue
            )
          }
          errorMessage={errors.CompanyFinishReports?.errorMessage}
          hasError={errors.CompanyFinishReports?.hasError}
          ref={CompanyFinishReportsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyFinishReports")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports: values,
              CompanyVisitReports,
            };
            const result = onChange(modelFields);
            values = result?.CompanyDestinationReports ?? values;
          }
          setCompanyDestinationReports(values);
          setCurrentCompanyDestinationReportsValue(undefined);
          setCurrentCompanyDestinationReportsDisplayValue("");
        }}
        currentFieldValue={currentCompanyDestinationReportsValue}
        label={"Company destination reports"}
        items={CompanyDestinationReports}
        hasError={errors?.CompanyDestinationReports?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "CompanyDestinationReports",
            currentCompanyDestinationReportsValue
          )
        }
        errorMessage={errors?.CompanyDestinationReports?.errorMessage}
        getBadgeText={getDisplayValue.CompanyDestinationReports}
        setFieldValue={(model) => {
          setCurrentCompanyDestinationReportsDisplayValue(
            model ? getDisplayValue.CompanyDestinationReports(model) : ""
          );
          setCurrentCompanyDestinationReportsValue(model);
        }}
        inputFieldRef={CompanyDestinationReportsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company destination reports"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search DestinationReport"
          value={currentCompanyDestinationReportsDisplayValue}
          options={destinationReportRecords
            .filter(
              (r) =>
                !CompanyDestinationReportsIdSet.has(
                  getIDValue.CompanyDestinationReports?.(r)
                )
            )
            .map((r) => ({
              id: getIDValue.CompanyDestinationReports?.(r),
              label: getDisplayValue.CompanyDestinationReports?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyDestinationReportsValue(
              destinationReportRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyDestinationReportsDisplayValue(label);
            runValidationTasks("CompanyDestinationReports", label);
          }}
          onClear={() => {
            setCurrentCompanyDestinationReportsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyDestinationReports?.hasError) {
              runValidationTasks("CompanyDestinationReports", value);
            }
            setCurrentCompanyDestinationReportsDisplayValue(value);
            setCurrentCompanyDestinationReportsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyDestinationReports",
              currentCompanyDestinationReportsDisplayValue
            )
          }
          errorMessage={errors.CompanyDestinationReports?.errorMessage}
          hasError={errors.CompanyDestinationReports?.hasError}
          ref={CompanyDestinationReportsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyDestinationReports")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              email,
              photo,
              location,
              isActive,
              CompanyUsers,
              CompanyClients,
              CompanySpecies,
              CompanyVarieties,
              CompanyGrowers,
              CompanyFields,
              CompanyExporters,
              CompanyPackings,
              CompanyPackFormats,
              CompanySizeFormats,
              CompanyIntakeFormats,
              CompanyIntakeReports,
              CompanyFinishReports,
              CompanyDestinationReports,
              CompanyVisitReports: values,
            };
            const result = onChange(modelFields);
            values = result?.CompanyVisitReports ?? values;
          }
          setCompanyVisitReports(values);
          setCurrentCompanyVisitReportsValue(undefined);
          setCurrentCompanyVisitReportsDisplayValue("");
        }}
        currentFieldValue={currentCompanyVisitReportsValue}
        label={"Company visit reports"}
        items={CompanyVisitReports}
        hasError={errors?.CompanyVisitReports?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "CompanyVisitReports",
            currentCompanyVisitReportsValue
          )
        }
        errorMessage={errors?.CompanyVisitReports?.errorMessage}
        getBadgeText={getDisplayValue.CompanyVisitReports}
        setFieldValue={(model) => {
          setCurrentCompanyVisitReportsDisplayValue(
            model ? getDisplayValue.CompanyVisitReports(model) : ""
          );
          setCurrentCompanyVisitReportsValue(model);
        }}
        inputFieldRef={CompanyVisitReportsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Company visit reports"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search VisitReport"
          value={currentCompanyVisitReportsDisplayValue}
          options={visitReportRecords
            .filter(
              (r) =>
                !CompanyVisitReportsIdSet.has(
                  getIDValue.CompanyVisitReports?.(r)
                )
            )
            .map((r) => ({
              id: getIDValue.CompanyVisitReports?.(r),
              label: getDisplayValue.CompanyVisitReports?.(r),
            }))}
          onSelect={({ id, label }) => {
            setCurrentCompanyVisitReportsValue(
              visitReportRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCompanyVisitReportsDisplayValue(label);
            runValidationTasks("CompanyVisitReports", label);
          }}
          onClear={() => {
            setCurrentCompanyVisitReportsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CompanyVisitReports?.hasError) {
              runValidationTasks("CompanyVisitReports", value);
            }
            setCurrentCompanyVisitReportsDisplayValue(value);
            setCurrentCompanyVisitReportsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "CompanyVisitReports",
              currentCompanyVisitReportsDisplayValue
            )
          }
          errorMessage={errors.CompanyVisitReports?.errorMessage}
          hasError={errors.CompanyVisitReports?.hasError}
          ref={CompanyVisitReportsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CompanyVisitReports")}
        ></Autocomplete>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
